import React from 'react';
import Paginate from '../Modules/Paginate'

function Walk(){
    return(
 
    <Paginate/>
        
    );
};

export default Walk;