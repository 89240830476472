import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import TTI from '../Images/www.ibresp.com.br-banner-tti.jpg';
import DOC from '../Images/www.ibresp.com.br-banner-documentacao.jpg';
import AVA from '../Images/www.ibresp.com.br-banner-avaliador.jpg';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className='container-fluid'>
      <div className='container my-5'>
        <div className='row justify-content-center'>
          <div className='col-8'>
              <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                  {/* <TTI text="First slide" /> */}
                  <img className='w-100' src={TTI} alt='btn'/>
                  <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ExampleCarouselImage text="Second slide" /> */}
                  <img className='w-100' src={DOC} alt='sonho'/>
                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  {/* <ExampleCarouselImage text="Third slide" /> */}
                  <img className='w-100' src={AVA} alt='btn'/>
                  <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
          </div>
        </div>
      </div>
    </div>

    
    
  );
}

export default ControlledCarousel;