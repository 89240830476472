import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Modules/Navbar";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import Walk from "./Pages/Walk";
import Btn from "./Pages/Btn";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TSM65BF8", // Substitua pelo seu ID do GTM
};


function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <Router>
    <Navbar/>
            <Routes>
                <Route index element={ <Home />} />
                <Route path="gyntubos-produtos" element={<Products />} />
                <Route path="paginar" element={<Walk />} />
                <Route path="botao" element={<Btn />} />
            </Routes>
    </Router>
  );
}

export default App;