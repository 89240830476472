import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import 'bootstrap/dist/css/bootstrap.min.css';
import './paginate.css';

// Lista simulada de URLs de imagens
const images = [
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-financiamento.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-tti.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-avaliador.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-documentacao.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-fotografia.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-captacao.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-marketing.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-perito-judicial-ibresp.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/09/www.ibresp.com.br-vendas-sep22.jpg",
  "https://www.ibresp.com.br/wp-content/uploads/2022/05/www.ibresp.com.br-banner-financiamento.jpg",
];

const PaginatedList = () => {
  const itemsPerPage = 3; // Exibir 3 imagens por página
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * itemsPerPage;
  const currentImages = images.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(images.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="container-fluid">
        <div className="container-lg mx-auto">
        <h2 className="text-primary fw-bold my-5">Produtos Gyntubos</h2>
        <div className="row justify-content-center g-5 mb-5">
            {currentImages.map((image, index) => (
                <div className="col-12 col-md-4">
                    <img className="img-fluid" src={image} alt={`Imagem ${index + 1}`} />
                </div>
            ))}
        </div>
        </div>

        <div className="page-info my-4 text-danger">
            <span className="text-grey">Página {currentPage + 1} de {pageCount}</span>
        </div>

      {/* Componente de paginação */}
      <div className="mb-5">
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Próxima"}
          breakLabel={"..."}
          pageCount={4} // número total de páginas
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination justify-content-center"} // Adiciona as classes do Bootstrap
          pageClassName={"page-item"} // Classe para cada página
          pageLinkClassName={"page-link"} // Classe para o link de cada página
          previousClassName={"page-item"} // Classe para o botão "Anterior"
          previousLinkClassName={"page-link"} // Classe para o link do botão "Anterior"
          nextClassName={"page-item"} // Classe para o botão "Próxima"
          nextLinkClassName={"page-link"} // Classe para o link do botão "Próxima"
          activeClassName={"active"} // Classe para a página ativa
        />
      </div>

    </div>


  );
};

export default PaginatedList;
