import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Images/logo-GYNTUBOS.svg';

function Nabvbar() {
  return (
    <Navbar expand="lg" className="fixed-top" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand className='w-logo' href="/">
          <img src={Logo} alt="Logo Gyntubos Insdustriais" title="Logo Gyntubos Insdustriais"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="#valvula">Válvulas</Nav.Link>
            <Nav.Link href="#conexao">Conexões</Nav.Link>
            <Nav.Link href="#tubos">Tubos</Nav.Link>
            <Nav.Link href="#incendio">Incêndio</Nav.Link>
            <Nav.Link href="#instrumentacao">Instrumentação</Nav.Link>
            <Nav.Link href="#automacao">Automação</Nav.Link>
            <Nav.Link href="#contato">Contato</Nav.Link>
            {/* <Nav.Link href="/gyntubos-produtos">Produtos</Nav.Link>
            <Nav.Link href="/paginar">Paginar</Nav.Link>
            <Nav.Link href="/botao">Botao</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nabvbar;