import React from 'react';
import { useEffect } from "react";
import Carousel from '../Modules/Caroussel'


function Btn(){

    useEffect(() => {
        document.title = "Botão | Gyntubos";
      }, []);
    

    return(
        <div>
            <Carousel />
        </div>
        
    );
};

export default Btn;